<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		v-if="!hasPendingOrder"
		class="card border-top-0 border-left-0 border-right-0"
		:class="{
			'border-bottom-0': orderDetailView,
		}">
		<b-alert
			show
			variant="none"
			class="mb-0 mt-0 p-0">
			<b-row :class="isStaff ? 'pb-2' : 'pb-1'">
				<b-col class="col-auto">
					<span
						class="d-block text-nowrap aligned-text text-right"
						:style="`width: ${width}px`">
						<b
							v-if="!isStaff">
							{{ translate('payment_number', { current: currentPayment, total: totalPayments }) }}:
						</b>
						<b
							v-else>
							{{ translate('paid_multi_payments') }}:
						</b>
					</span>
				</b-col>
				<b-col class="p-0">
					<b-button
						v-if="!isStaff"
						variant="primary"
						class="btn-sm"
						@click="isLastPayment ? handlePay() : showModal()">
						{{ translate('pay_installment') }}
					</b-button>
					<span
						v-else>
						{{ translate('x_of_y', { x: currentPayment - 1, y: totalPayments }) }}
					</span>
				</b-col>
			</b-row>
			<b-row>
				<b-col class="col-auto">
					<span
						class="d-block text-nowrap aligned-text text-right"
						:style="`width: ${width}px`"><b>{{ translate('payday_limit') }}: </b></span>
				</b-col>
				<b-col class="p-0">
					<span>{{ nextPaymentDate ? $moment(nextPaymentDate).format(dateFormat) : '' }}</span>
				</b-col>
			</b-row>
			<b-row class="pb-3 pt-2">
				<b-col class="col-auto">
					<span
						class="d-block text-nowrap aligned-text text-right"
						:style="`width: ${width}px`"><b>{{ translate('payment_amount') }}: </b></span>
				</b-col>
				<b-col class="p-0">
					<span>{{ translate('payment_amount_description', { amount: paymentAmount }) }}</span>
				</b-col>
			</b-row>
		</b-alert>
		<b-modal
			ref="payModal"
			size="sm"
			centered
			no-close-on-backdrop
			no-close-on-esc
			:cancel-title="translate('cancel')"
			:ok-title="translate('ok')"
			@ok="handlePay">
			<template #modal-title>
				{{ translate('payment_type') }}
			</template>
			<b-radio-group
				v-model="paymentType"
				stacked>
				<b-radio value="next">
					{{ translate('pay_installment_x_of_y', meta.payment_count) }}
				</b-radio>
				<b-radio
					v-if="maxPayments > 2"
					value="in_advance">
					{{ translate('pay_in_advance') }}
				</b-radio>
				<b-radio
					v-if="!isLastPayment"
					value="settle">
					{{ translate('settle') }}
				</b-radio>
			</b-radio-group>
			<div
				v-if="paymentType === 'in_advance'"
				class="mt-3">
				<label for="inline-form-input-username">{{ translate('number_of_payments') }}</label>
				<div class="d-flex">
					<b-button
						style="border-top-right-radius: 0; border-bottom-right-radius: 0"
						:disabled="payments === 2"
						@click="lessPayments">
						-
					</b-button>
					<b-form-input
						id="inline-form-input-username"
						v-model="payments"
						type="number"
						style="border-radius: 0; max-width: 100px; text-align: center" />
					<b-button
						style="border-bottom-left-radius: 0; border-top-left-radius: 0"
						:disabled="payments === maxPayments"
						@click="morePayments">
						+
					</b-button>
				</div>
				<div v-if="payments === maxPayments">
					<span class="text-success">{{ translate('will_settle_debt') }}</span>
				</div>
			</div>
		</b-modal>
	</div>
	<div v-else>
		<b-alert
			show
			variant="warning">
			<b-row>
				<b-col v-if="orderDetailView">
					<span v-html="translate('unresolved_payment')" />
				</b-col>
				<b-col v-if="!orderDetailView">
					<span v-html="translate('unresolved_payment_v2')" />
				</b-col>
				<b-col
					v-if="orderDetailView"
					cols="auto d-flex align-items-center">
					<b-button
						variant="primary"
						@click="$router.push({ name: 'OrdersMultiPayment' })">
						{{ translate('go_to_payment_details') }}
					</b-button>
				</b-col>
			</b-row>
		</b-alert>
	</div>
</template>

<script>
import { MultiPayment } from '@/translations';
import { MDY_FORMAT } from '@/settings/Dates';

export default {
	name: 'PayMultiPayment',
	messages: [MultiPayment],
	props: {
		orderDetailView: {
			type: Boolean,
			default: false,
		},
		meta: {
			type: Object,
			default() {
				return {};
			},
		},
		hasPendingOrder: {
			type: Boolean,
			default: false,
		},
		isStaff: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateFormat: MDY_FORMAT,
			paymentType: 'next',
			payments: 2,
		};
	},
	computed: {
		isLastPayment() {
			const { current, total } = this.meta.payment_count;
			return current === total;
		},
		maxPayments() {
			const { current, total } = this.meta.payment_count;
			return total - current + 1;
		},
		nextPaymentDate() {
			try {
				return this.meta.next_payment_date;
			} catch (error) {
				return null;
			}
		},
		paymentAmount() {
			try {
				return this.meta.payment_amount;
			} catch (error) {
				return '';
			}
		},
		currentPayment() {
			try {
				return this.meta.payment_count.current;
			} catch (error) {
				return null;
			}
		},
		totalPayments() {
			try {
				return this.meta.payment_count.total;
			} catch (error) {
				return null;
			}
		},
		width() {
			return this.language === 'es' ? 215 : 175;
		},
	},
	methods: {
		showModal() {
			this.$refs.payModal.show();
		},
		morePayments() {
			this.payments = Math.min(this.maxPayments, this.payments + 1);
		},
		lessPayments() {
			this.payments = Math.max(2, this.payments - 1);
		},
		handlePay() {
			const payments = {
				next: 1,
				in_advance: this.payments,
				settle: this.maxPayments,
			}[this.paymentType];
			this.$emit('pay-order', { payments });
		},
	},
};
</script>
