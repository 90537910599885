<template>
	<div>
		<h3
			v-if="orderDetailView && hasPendingPayments"
			class="m-2 mb-3">
			{{ translate('multi_payment_plan') }}
			<a
				class="pointer text-muted font-weight-light text-small pl-1"
				@click="showModal = true">
				<i class="fa fa-eye" />
			</a>
		</h3>
		<b-alert
			v-if="isStaff"
			show
			variant="secondary">
			<div class="row">
				<div class="col">
					<search
						v-model="userId"
						:label-text="translate('autocomplete_label')"
						:endpoint="endpoint"
						:empty-result-message="translate('data_not_found')"
						:default-params="{role:'distributor', validateCountry: false}"
						:placeholder="'autocomplete_placeholder'"
						class="mb-3"
						q="label"
						return-key="id" />
					<b-button
						variant="primary"
						@click="getMultiPayments">
						{{ translate('search') }}
					</b-button>
					<b-button
						variant="secondary"
						class="ml-2"
						@click="userId = ''; getMultiPayments()">
						{{ translate('clear') }}
					</b-button>
				</div>
			</div>
		</b-alert>
		<pay-multi-payment
			v-if="hasPendingPayments"
			:order-detail-view="orderDetailView"
			:meta="meta"
			:is-staff="isStaff"
			:has-pending-order="hasPendingOrder" />
		<b-alert
			v-if="isStaff && !loading && !hasData && !firstTime"
			variant="warning"
			show>
			{{ translate('no_multi_payments') }}
		</b-alert>
		<div
			v-if="!orderDetailView && hasData">
			<h4 class="mt-4 mb-3">
				{{ translate('order') }} <a :href="`/orders/${parentOrderId}/details`">{{ parentOrderId }}</a> - {{ translate('payment_details') }}
				<a
					v-if="!orderDetailView"
					class="pointer text-muted font-weight-light text-small pl-1"
					@click="showModal = true">
					<i class="fa fa-eye" />
				</a>
			</h4>
			<div class="table-responsive">
				<table class="table table-hover table-striped text-nowrap">
					<thead>
						<tr>
							<th
								class="border-top-0"
								style="width:5px">
								#
							</th>
							<th
								class="border-top-0 width-150">
								{{ translate('payment_status') }}
							</th>
							<th class="border-top-0 width-150">
								{{ translate('scheduled_payment_date') }}
							</th>
							<th class="border-top-0 width-200">
								{{ translate('invoice_id') }}
							</th>
							<th class="border-top-0 width-150 text-right">
								{{ translate('total') }}
							</th>
							<th class="border-top-0 width-200">
								{{ translate('date_paid') }}
							</th>
							<th class="border-top-0">
								{{ translate('tracking_number') }}
							</th>
							<th class="border-top-0">
								{{ translate('tracking_status') }}
							</th>
							<th class="border-top-0 width-200 text-center">
								{{ translate('options') }}
							</th>
						</tr>
					</thead>
					<tbody v-if="!loading && hasData">
						<tr
							v-for="(item, index) in data"
							:key="index">
							<td
								class="align-middle"
								style="width:5px">
								{{ (index + 1) }}
							</td>
							<td
								class="align-middle">
								{{ translate(item.attributes.status) }}
							</td>
							<td class="align-middle">
								{{ item.attributes.scheduled_payment_date }}
							</td>
							<td class="align-middle">
								{{ item.attributes.invoice_id }}
							</td>
							<td class="align-middle text-right">
								{{ item.attributes.total_amount }}
							</td>
							<td class="align-middle">
								{{ isPaid(item) ? $moment(item.attributes.date_paid.date).format(dateFormat) : '' }}
							</td>
							<td class="align-middle text-left">
								<template v-if="(item.attributes.tracking.length !== 0)">
									<template
										v-if="item.attributes.tracking.tracking_url">
										<a
											:href="item.attributes.tracking.tracking_url"
											target="_blank">
											{{ item.attributes.tracking['tracking_number'] }}
										</a>
									</template>
									<template v-else>
										{{ item.attributes.tracking['tracking_number'] }}
									</template>
								</template>
							</td>
							<td class="align-middle">
								{{ translate(item.attributes.tracking_status) }}
							</td>
							<td class="align-middle text-center">
								<b-button
									v-if="isPaid(item)"
									v-b-tooltip.hover
									:title="translate('download')"
									:disabled="loading"
									class="btn mx-1 bg-primary-alt"
									@click="download(item.attributes.payment_order_id, item.attributes.invoice_id)">
									<i
										class="fa fa-download"
										aria-hidden="true" />
								</b-button>
								<b-button
									v-if="!isPaid(item) && !isStaff"
									v-b-tooltip.hover
									:title="translate('change_payment_method')"
									:disabled="loading"
									class="btn mx-1 bg-primary-alt"
									@click="$router.push({ name: 'ChangePaymentMethod', params: { order_id: item.attributes.payment_order_id } })">
									<i
										class="fa fa-money-bill-wave"
										aria-hidden="true" />
								</b-button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<multi-payment-products-modal
			:products="productsDistribution"
			:loading="loading"
			:show.sync="showModal" />
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('no_records_found')"
			:loading="loading" />
	</div>
</template>

<script>
import {
	MultiPayment as multiPaymentMessages, Grids, OrdersDetails, TrackingStatus,
} from '@/translations';
import { YMDHMS_FORMAT } from '@/settings/Dates';
import Order from '@/util/Order';
import MultiPaymentProductsModal from '@/components/MultiPaymentProductsModal';
import PayMultiPayment from '@/views/Orders/components/PayMultiPayment';
import { admin } from '@/settings/Roles';
import { SEARCH_USERS } from '@/config/endpoint';

const { endpoint } = SEARCH_USERS;

export default {
	name: 'MultiPaymentsIndex',
	messages: [multiPaymentMessages, Grids, OrdersDetails, TrackingStatus],
	components: { PayMultiPayment, MultiPaymentProductsModal },
	props: {
		orderDetailView: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			dateFormat: YMDHMS_FORMAT,
			paymentOrder: new Order(),
			downloadInvoice: new Order(),
			alert: new this.$Alert(),
			showModal: false,
			orderMultiPayments: new Order(),
			admin,
			endpoint,
			userId: 0,
			firstTime: true,
		};
	},
	computed: {
		isStaff() {
			return this.admin.includes(this.$user.details().type);
		},
		hasPendingOrder() {
			return this.data.filter((item) => item.attributes.is_pending_order).length > 0;
		},
		loading() {
			return !!this.orderMultiPayments.data.loading;
		},
		hasPendingPayments() {
			try {
				const { data } = this.orderMultiPayments.data.response.data;
				return data.some((item) => item.attributes.status !== 'paid');
			} catch (e) {
				return false;
			}
		},
		data() {
			try {
				const { data } = this.orderMultiPayments.data.response.data;
				let index = data.findIndex((item) => item.attributes.status === 'pending');
				index = index === -1 ? data.length - 1 : index;
				index = data[index].attributes.is_pending_order ? index + 1 : index;
				return data.slice(0, index).reverse();
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = Object.keys(this.data).length;
			return !!response;
		},
		productsDistribution() {
			try {
				const { meta } = this.orderMultiPayments.data.response.data;
				return meta.product_distribution_format;
			} catch (error) {
				return [];
			}
		},
		meta() {
			try {
				const { meta } = this.orderMultiPayments.data.response.data;
				return meta;
			} catch (error) {
				return {};
			}
		},
		parentOrderId() {
			try {
				return this.data[0].attributes.parent_order_id;
			} catch (error) {
				return '';
			}
		},
	},
	mounted() {
		if (!this.isStaff) {
			this.getMultiPayments();
		}
	},
	methods: {
		isPaid(item) {
			return item.attributes.status === 'paid';
		},
		download(orderId, invoiceId) {
			const config = {
				allowEscapeKey: false,
				allowOutsideClick: false,
			};

			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), config);
			this.downloadInvoice.getInvoice(orderId).then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]), { type: response.headers['content-type'] });
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${this.translate('invoice_id')} ${invoiceId}.pdf`);
				this.$el.appendChild(link);
				link.click();
				this.alert.close();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		getMultiPayments() {
			let userId = this.$user.details().id;
			if (admin.includes(this.$user.details().type)) {
				userId = this.userId;
			}
			this.firstTime = !userId;
			this.orderMultiPayments.getMultiPayments(userId).then(() => {
				if (this.$route.query.option && this.$route.query.option === 'multi_payments') {
					const interval = setInterval(() => {
						const element = document.getElementById('multiPaymentPlanSection');
						if (element) {
							document.getElementById('multiPaymentPlanSection').scrollIntoView({ behavior: 'smooth' });
							clearInterval(interval);
						}
					}, 300);
				}
			});
		},
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (!admin.includes(vm.$user.details().type) && !vm.$user.details().active_multi_payment) {
				vm.$router.replace({ name: 'Home' });
			}
		});
	},
};
</script>
<style scoped>
.width-200 {
		width: 200px;
}
</style>
