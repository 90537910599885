<template>
	<b-modal
		ref="productsModal"
		scrollable
		hide-footer
		header-class="mx-auto w-100 bg-dark text-white"
		:size="['xs', 'sm'].includes(windowWidth) ? 'xl' : 'lg-custom'"
		:body-class="['xs'].includes(windowWidth) ? 'p-2' : ''"
		centered
		@hide="close">
		<template v-slot:modal-header>
			<h5 class="modal-title text-white">
				{{ translate('product_distribution') }}
			</h5>
			<button
				type="button"
				aria-label="Close"
				class="close text-white"
				@click="close">
				×
			</button>
		</template>
		<div
			v-if="!loading"
			class="row no-gutters justify-content-center">
			<div
				:class="['xs', 'sm'].includes(windowWidth) ? '' : 'px-2'"
				class="col-12">
				<div
					class="border-secondary mb-2 px-2 pt-2 pb-0"
					style="border: 1px solid black; border-radius: 5px">
					<div class="row mx-2 mt-1 mb-">
						<div class="col-12">
							<div class="col-12">
								<div
									v-for="(paymentItems, i) in products"
									:key="i"
									:class="i < products.length - 1 ? 'border border-secondary border-top-0 border-left-0 border-right-0 mb-2' : ''">
									<strong> {{ translate('payment_no', { no: paymentItems.payment_no }) }} </strong>
									<div
										class="row pb-3 pl-2 pt-1">
										<div
											v-for="(child, j) in paymentItems.products"
											:key="j"
											class="col-12 col-md-6">
											<div>
												<img
													:src="child.image"
													width="50px"
													class="img-fluid">
												<span
													v-if="child.qty"
													class="pl-1">
													<strong> {{ child.qty }} x </strong> {{ child.name }}
												</span>
												<span v-else>{{ child.name }} </span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:class="loading ? 'mt-4' : ''"
			class="mx-auto" />
	</b-modal>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { MultiPayment as multiPaymentMessages } from '@/translations';
import IsLoading from '@/components/Loading';

export default {
	name: 'MultiPaymentProductsModal',
	messages: [multiPaymentMessages],
	components: {
		IsLoading,
	},
	mixins: [WindowSizes],
	props: {
		products: {
			type: Array,
			required: true,
			default: () => [],
		},
		loading: {
			type: Boolean,
			default: false,
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		smallSize() {
			return ['xs', 'sm', 'md'].includes(this.windowWidth);
		},
	},
	watch: {
		show: {
			handler(show) {
				if (show) {
					this.open();
				}
			},
			immediate: true,
		},
	},
	methods: {
		open() {
			this.$refs.productsModal.show();
		},
		close() {
			this.$refs.productsModal.hide();
			this.$emit('update:show', false);
		},
	},
};
</script>
<style>
/* Custom b-modal sizes https://github.com/bootstrap-vue/bootstrap-vue/issues/632#issuecomment-441719709 */
.modal-lg-custom {
	max-width: 880px !important;
	width: 880px !important;
}
</style>
